.search-bar {
  position: relative;
  width: 100%; /* 너비 조정 */
  display: inline-block; /* 인라인 블록으로 설정 */
}
.search-bar input {
  width: 100%;
  height: 35px;
  padding: 0 40px 0 10px;
  border: 1px solid #e0e0e0;
  border-radius: 30px;
  font-size: 14px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.search-bar::after {
  content: "";
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-100%);
  width: 20px;
  height: 20px;
  background-image: url("../assets/search_gray.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  pointer-events: none;
}

/* indecator */
/* indicator */
.indicator-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner-circle {
  width: 150px;
  height: 150px;
  border: 13px solid #fbdda1; /* 두께 13px로 조정 */
  border-top: 13px solid #ffaa00; /* 회전하는 부분 */
  border-radius: 50%;
  animation: spin 2s linear infinite; /* 회전 애니메이션 */
  position: absolute; /* 부모 요소를 기준으로 절대 위치 */
  box-sizing: border-box; /* border 두께 유지 */
}

.inner-image {
  width: 60px; /* 이미지 크기 조절 */
  height: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000; /* 모든 요소의 가장 위에 위치 */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
