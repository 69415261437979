body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0; /* 앱 외부 배경색 */
}

.app-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.divider {
  border-bottom: 10px solid #eee;
  width: 100%; /* 너비를 100%로 설정하여 부모 요소의 너비를 채우도록 함 */
}

.line {
  border-bottom: 1px solid #eee;
  margin: 10px 10px 10px 10px;
}
.app-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60px; /* 헤더 높이 */
  z-index: 10;
}

.app-content {
  height: calc(100% - 120px); /* 헤더와 푸터 높이를 뺀 나머지 */
  position: absolute;
  top: 60px; /* 헤더 아래에 위치 */
  left: 0;
  right: 0;
  bottom: 60px; /* 푸터 위에 위치 */
  box-sizing: border-box;
  padding: 25px 0 10px 0;
}
.app-content2 > div {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}
.app-content2 > div > div {
  border: 1px solid #ddd;
  top: -1px;
}

.app-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px; /* 푸터 높이 */
  z-index: 10;
}

/* 기존의 다른 스타일들... */
a {
  color: #7d7d7d;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

/* 스크롤바 제거 전역 설정 */
*::-webkit-scrollbar {
  display: none;
}
* {
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
}
