/* Calendar.css */
.calendar-container {
  width: 100%;
  max-width: 400px;
  margin-bottom: 1rem;
}

.selected-date {
  background-color: #fbbf24 !important;
  color: #444444 !important;
}

.react-calendar {
  width: 100%;
  max-width: 100%;
  background: white;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.5em;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1rem;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  font-size: 1rem;
  padding: 0.5rem;
  color: #444444;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #fde68a;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.85em;
  padding: 0.5rem 0;
  color: #444;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5rem;
}

.react-calendar__month-view__days__day--weekend {
  color: #911410;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 1.1rem 0.5rem;
  background: none;
  text-align: center;
  line-height: 1;
  font-size: 1rem;
}

.react-calendar__tile:disabled {
  opacity: 0.5;
  background-color: #f3f4f6;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #fde68a;
}

.react-calendar__tile--now {
  font-weight: bold;
  background: #fef3c7;
}

.react-calendar__tile--active {
  font-weight: bold;
  background: #fbbf24;
  color: #444;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #f59e0b;
}

/* 반응형 디자인을 위한 미디어 쿼리 */
@media screen and (max-width: 480px) {
  .react-calendar {
    padding: 0.5rem;
  }

  .react-calendar__tile {
    padding: 0.82rem 0.25rem;
    font-size: 0.9rem;
  }
}
